import React from 'react';

const IconPhp = ({ ...styles }) => (
  <svg x="0" y="0" viewBox="0 0 700 301" xmlns="http://www.w3.org/2000/svg" {...styles}>
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="a">
        <path d="M11.52 162C11.52 81.677 135.307 16.561 288 16.561S564.481 81.677 564.481 162c0 80.322-123.788 145.439-276.481 145.439-152.693 0-276.48-65.117-276.48-145.439" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="c">
        <path d="M0 324h576V0H0v324z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="d">
        <path d="M0 324h576V0H0v324z" />
      </clipPath>
      <radialGradient
        cx="0"
        cy="0"
        fx="0"
        fy="0"
        gradientTransform="matrix(363.05789 0 0 -363.05789 177.52 256.307)"
        gradientUnits="userSpaceOnUse"
        id="b"
        r="1"
        spreadMethod="pad"
      >
        <stop offset="0" stopColor="#aeb2d5" />
        <stop offset=".3" stopColor="#aeb2d5" />
        <stop offset=".75" stopColor="#484c89" />
        <stop offset="1" stopColor="#484c89" />
      </radialGradient>
    </defs>
    <g clipPath="url(#a)" transform="matrix(1.25 0 0 -1.25 -4.4 394.299)">
      <path
        d="M11.52 162C11.52 81.677 135.307 16.561 288 16.561S564.481 81.677 564.481 162c0 80.322-123.788 145.439-276.481 145.439-152.693 0-276.48-65.117-276.48-145.439"
        fill="url(#b)"
      />
    </g>
    <g clipPath="url(#c)" transform="matrix(1.25 0 0 -1.25 -4.4 394.299)">
      <path
        d="M288 27.36c146.729 0 265.68 60.28 265.68 134.64 0 74.36-118.951 134.641-265.68 134.641S22.32 236.36 22.32 162.001C22.32 87.64 141.271 27.358 288 27.358"
        fill="#777bb3"
      />
    </g>
    <g clipPath="url(#d)" transform="matrix(1.25 0 0 -1.25 -4.4 394.299)">
      <path d="M161.734 145.307c12.065 0 21.072 2.225 26.771 6.61 5.638 4.342 9.532 11.863 11.573 22.354 1.903 9.806 1.178 16.653-2.154 20.348-3.407 3.774-10.773 5.688-21.893 5.688h-19.28l-10.69-55h15.673zm-63.063-67.75a2.998 2.998 0 00-2.944 3.572l28.328 145.75a3 3 0 002.945 2.428h61.054c19.188 0 33.47-5.21 42.447-15.487 9.025-10.331 11.812-24.772 8.283-42.921-1.436-7.394-3.906-14.261-7.34-20.41-3.44-6.154-7.985-11.85-13.512-16.93-6.616-6.191-14.104-10.681-22.236-13.323-8.003-2.607-18.28-3.93-30.548-3.93h-24.722l-7.06-36.321a3 3 0 00-2.944-2.428h-31.75z" />
      <path
        d="M159.224 197.307h16.808c13.42 0 18.083-2.945 19.667-4.7 2.628-2.914 3.124-9.058 1.435-17.767-1.898-9.75-5.416-16.663-10.458-20.545-5.162-3.974-13.554-5.988-24.941-5.988H149.7l9.523 49zm28.83 35H127a6 6 0 01-5.89-4.855L92.784 81.702a6 6 0 015.889-7.144h31.75a6 6 0 015.89 4.854l6.588 33.895h22.249c12.582 0 23.174 1.372 31.479 4.077 8.54 2.775 16.399 7.48 23.354 13.984 5.752 5.292 10.49 11.232 14.08 17.657 3.59 6.427 6.17 13.594 7.668 21.302 3.715 19.104.697 34.402-8.97 45.466-9.571 10.958-24.613 16.514-44.705 16.514m-45.633-90h19.313c12.8 0 22.336 2.411 28.6 7.234 6.267 4.824 10.493 12.875 12.689 24.157 2.1 10.832 1.144 18.476-2.871 22.93-4.02 4.452-12.06 6.68-24.121 6.68h-21.754l-11.856-61m45.633 84c18.367 0 31.766-4.82 40.188-14.462 8.42-9.64 10.957-23.098 7.597-40.375-1.383-7.117-3.722-13.624-7.015-19.519-3.297-5.899-7.602-11.293-12.922-16.184-6.34-5.933-13.383-10.16-21.133-12.679-7.75-2.525-17.621-3.782-29.621-3.782h-27.196l-7.531-38.75h-31.75L127 226.307h61.055"
        fill="#fff"
      />
      <path d="M311.583 116.307c-.896 0-1.745.4-2.314 1.092a2.994 2.994 0 00-.631 2.48l12.531 64.489c1.192 6.133.898 10.535-.827 12.395-1.056 1.137-4.228 3.044-13.607 3.044h-22.702l-15.755-81.072a3 3 0 00-2.945-2.428h-31.5a2.998 2.998 0 00-2.945 3.572l28.328 145.75a3 3 0 002.945 2.428h31.5a3 3 0 002.945-3.572l-6.836-35.178h24.422c18.605 0 31.221-3.28 38.569-10.028 7.49-6.884 9.827-17.891 6.947-32.72l-13.18-67.824a3 3 0 00-2.945-2.428h-32z" />
      <path
        d="M293.661 271.057h-31.5a6 6 0 01-5.89-4.855l-28.328-145.75a5.998 5.998 0 015.89-7.145h31.5a6 6 0 015.89 4.855l15.283 78.645h20.23c9.362 0 11.327-2 11.406-2.086.568-.61 1.315-3.44.082-9.78l-12.53-64.49a5.998 5.998 0 015.89-7.144h32a6 6 0 015.89 4.855l13.178 67.825c3.093 15.921.447 27.864-7.86 35.5-7.929 7.281-21.209 10.82-40.6 10.82h-20.784l6.143 31.605a6.001 6.001 0 01-5.89 7.145m0-6l-7.53-38.75h28.061c17.657 0 29.836-3.082 36.54-9.238 6.702-6.16 8.71-16.14 6.031-29.938l-13.18-67.824h-32l12.531 64.488c1.426 7.336.902 12.34-1.574 15.008-2.477 2.668-7.746 4.004-15.805 4.004H281.56l-16.226-83.5h-31.5l28.328 145.75h31.5"
        fill="#fff"
      />
      <path d="M409.55 145.307c12.065 0 21.072 2.225 26.77 6.61 5.639 4.34 9.533 11.862 11.575 22.354 1.903 9.806 1.178 16.653-2.155 20.348-3.407 3.774-10.773 5.688-21.893 5.688h-19.281l-10.69-55h15.674zm-63.062-67.75a2.999 2.999 0 00-2.945 3.572l28.328 145.75a3.002 3.002 0 002.946 2.428h61.053c19.189 0 33.47-5.21 42.448-15.487 9.025-10.33 11.81-24.771 8.283-42.921-1.438-7.394-3.907-14.261-7.342-20.41-3.44-6.154-7.984-11.85-13.511-16.93-6.616-6.191-14.104-10.681-22.236-13.323-8.003-2.607-18.281-3.93-30.548-3.93H388.24l-7.057-36.321a3.001 3.001 0 00-2.946-2.428h-31.75z" />
      <path
        d="M407.04 197.307h16.807c13.421 0 18.083-2.945 19.667-4.7 2.63-2.914 3.125-9.058 1.435-17.766-1.898-9.75-5.417-16.664-10.458-20.546-5.162-3.974-13.554-5.988-24.94-5.988h-12.034l9.522 49zm28.83 35h-61.054a6 6 0 01-5.889-4.855L340.6 81.702a6 6 0 015.889-7.144h31.75a6 6 0 015.89 4.854l6.587 33.895h22.25c12.581 0 23.173 1.372 31.478 4.077 8.541 2.775 16.401 7.481 23.356 13.986 5.752 5.291 10.488 11.23 14.078 17.655 3.591 6.427 6.171 13.594 7.668 21.302 3.715 19.105.697 34.403-8.969 45.467-9.572 10.957-24.613 16.513-44.706 16.513m-45.632-90h19.312c12.801 0 22.336 2.411 28.601 7.234 6.267 4.824 10.492 12.875 12.688 24.157 2.102 10.832 1.145 18.476-2.87 22.93-4.02 4.452-12.06 6.68-24.122 6.68h-21.754l-11.855-61m45.632 84c18.367 0 31.766-4.82 40.188-14.462 8.422-9.64 10.957-23.098 7.597-40.375-1.383-7.117-3.722-13.624-7.015-19.519-3.297-5.899-7.602-11.293-12.922-16.184-6.34-5.933-13.383-10.16-21.133-12.679-7.75-2.525-17.62-3.782-29.62-3.782h-27.197l-7.53-38.75h-31.75l28.328 145.75h61.054"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IconPhp;
