import React from 'react';

const IconCpp = ({ ...styles }) => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
    <g clip-path="url(#clip0_1351_59959)">
      <g clip-path="url(#clip1_1351_59959)">
        <path
          d="M25.4013 21.5646C25.5872 21.2353 25.7034 20.8745 25.7034 20.5373V9.08012C25.7034 8.75078 25.5872 8.38217 25.4013 8.05283L13.8516 14.8127L25.4013 21.5646Z"
          fill="#00599C"
        />
        <path
          d="M14.8738 28.0657L24.6729 22.3411C24.9517 22.1764 25.2151 21.8941 25.401 21.5647L13.8513 14.8127L2.30151 21.5647C2.48743 21.8941 2.74305 22.1764 3.02967 22.3411L12.8288 28.0657C13.3865 28.3951 14.316 28.3951 14.8738 28.0657Z"
          fill="#004482"
        />
        <path
          d="M25.4011 8.06086C25.2152 7.73146 24.9596 7.44915 24.673 7.2845L14.8739 1.55983C14.3084 1.23046 13.3866 1.23046 12.8211 1.55983L3.02202 7.2845C2.46429 7.61385 1.99951 8.42158 1.99951 9.08033V20.5375C1.99951 20.8669 2.11571 21.2354 2.30162 21.5647L13.8514 14.8128L25.4011 8.06086Z"
          fill="#659AD2"
        />
        <path
          d="M13.8512 22.8115C9.49778 22.8115 5.94995 19.2199 5.94995 14.8127C5.94995 10.4055 9.49778 6.81387 13.8512 6.81387C16.6631 6.81387 19.2813 8.34305 20.6912 10.8133L17.2751 12.813C16.5701 11.5818 15.261 10.8133 13.8512 10.8133C11.6745 10.8133 9.90055 12.6091 9.90055 14.8127C9.90055 17.0163 11.6745 18.8121 13.8512 18.8121C15.261 18.8121 16.5701 18.0436 17.2751 16.8124L20.6912 18.8121C19.2813 21.2745 16.6631 22.8115 13.8512 22.8115Z"
          fill="white"
        />
        <path
          d="M21.7524 14.3657H20.8771V13.4796H19.994V14.3657H19.1187V15.2518H19.994V16.1458H20.8771V15.2518H21.7524V14.3657Z"
          fill="white"
        />
        <path
          d="M25.0446 14.3657H24.1693V13.4796H23.2862V14.3657H22.4109V15.2518H23.2862V16.1458H24.1693V15.2518H25.0446V14.3657Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1351_59959">
        <rect width="28" height="28" fill="white" transform="translate(0 0.809265)" />
      </clipPath>
      <clipPath id="clip1_1351_59959">
        <rect width="24" height="27" fill="white" transform="translate(1.99951 1.30878)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCpp;
