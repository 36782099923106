import React from 'react';

const IconC = ({ ...styles }) => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
    <g clip-path="url(#clip0_1351_59955)">
      <g clip-path="url(#clip1_1351_59955)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.118 1.49532C13.5403 1.25103 14.0559 1.25103 14.4783 1.49532C16.5652 2.70389 22.8385 6.34889 24.9193 7.55748C25.3416 7.80172 25.6025 8.25176 25.6025 8.74672C25.6025 11.1703 25.6025 18.4539 25.6025 20.8774C25.6025 21.366 25.3416 21.8224 24.9193 22.0667C22.8385 23.2753 16.5652 26.9203 14.4783 28.1289C14.0559 28.3732 13.5403 28.3732 13.118 28.1289C11.0373 26.9139 4.76398 23.2689 2.68323 22.0603C2.26087 21.816 2 21.366 2 20.871C2 18.4474 2 11.1639 2 8.7403C2 8.25176 2.26087 7.7953 2.68323 7.55106C4.76398 6.34889 11.0373 2.70389 13.118 1.49532Z"
          fill="#004482"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.18634 21.4946C2.01863 21.2632 2 21.051 2 20.7553C2 18.351 2 11.1061 2 8.70176C2 8.21317 2.26087 7.76319 2.67702 7.51889C4.75776 6.31675 11.0062 2.69104 13.0807 1.4889C13.5031 1.24461 14.0683 1.25104 14.4907 1.49533C16.5652 2.69747 22.795 6.29104 24.8696 7.49319C25.0373 7.58963 25.1677 7.70535 25.2795 7.86606L2.18634 21.4946Z"
          fill="#659AD2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.8011 5.8089C18.6024 5.8089 22.4968 9.83964 22.4968 14.8089C22.4968 19.7782 18.6024 23.8089 13.8011 23.8089C8.99986 23.8089 5.10547 19.7782 5.10547 14.8089C5.10547 9.83964 8.99986 5.8089 13.8011 5.8089ZM13.8011 10.3089C16.1987 10.3089 18.1489 12.3275 18.1489 14.8089C18.1489 17.2903 16.1987 19.3089 13.8011 19.3089C11.4036 19.3089 9.4533 17.2903 9.4533 14.8089C9.4533 12.3275 11.4036 10.3089 13.8011 10.3089Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.2857 7.87247C25.6087 8.18102 25.6025 8.65034 25.6025 9.01673C25.6025 11.4596 25.5839 18.396 25.6087 20.8325C25.6087 21.0896 25.528 21.3532 25.4099 21.5589L13.5466 14.8089L25.2857 7.87247Z"
          fill="#00599C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1351_59955">
        <rect width="28" height="28" fill="white" transform="translate(0 0.809265)" />
      </clipPath>
      <clipPath id="clip1_1351_59955">
        <rect width="24" height="27" fill="white" transform="translate(2 1.30887)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconC;
