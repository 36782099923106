import React from 'react';

const IconJavascript = ({ ...styles }) => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
    <g clip-path="url(#clip0_2131_12846)">
      <path d="M30 0.192139H0V30.1922H30V0.192139Z" fill="#F0DB4F" />
      <path
        d="M27.5521 23.0401C27.3361 21.6721 26.4481 20.5201 23.8081 19.4401C22.8961 19.0081 21.8641 18.7201 21.5521 18.0241C21.4321 17.6161 21.4321 17.4001 21.5041 17.1361C21.6961 16.3441 22.6561 16.1041 23.4001 16.3201C23.8801 16.4881 24.3361 16.8481 24.6241 17.4481C25.9201 16.6081 25.9201 16.6081 26.8081 16.0321C26.4721 15.5281 26.3041 15.2881 26.0881 15.0721C25.3201 14.2081 24.2401 13.7521 22.5601 13.8001C22.2721 13.8481 21.9601 13.8721 21.6721 13.9201C20.8321 14.1361 20.0161 14.5681 19.5361 15.1681C18.1201 16.7761 18.5281 19.6081 20.2561 20.7601C21.9601 22.0321 24.4561 22.3201 24.7681 23.5201C25.0801 24.9841 23.6881 25.4641 22.3201 25.2961C21.3121 25.0801 20.7361 24.5761 20.1361 23.6401C19.0081 24.2881 19.0081 24.2881 17.8561 24.9601C18.1201 25.5601 18.4081 25.8241 18.8881 26.3521C21.0721 28.5601 26.4961 28.4401 27.4801 25.1041C27.5041 24.9841 27.7441 24.2161 27.5521 23.0401ZM16.3201 13.9921H13.5121C13.5121 16.4161 13.5121 18.8161 13.5121 21.2401C13.5121 22.7761 13.5841 24.1921 13.3441 24.6241C12.9361 25.4881 11.8801 25.3681 11.3761 25.2001C10.8721 24.9601 10.6321 24.6241 10.3441 24.1201C10.2721 23.9761 10.2001 23.8801 10.1761 23.8561C9.40812 24.3121 8.66412 24.7921 7.89612 25.2481C8.28012 26.0161 8.83212 26.7121 9.55212 27.1441C10.6321 27.7921 12.0481 27.9841 13.5601 27.6481C14.5441 27.3601 15.3841 26.7841 15.8161 25.8721C16.4641 24.6961 16.3201 23.2801 16.3201 21.6961C16.3201 19.1281 16.3201 16.5601 16.3201 13.9921Z"
        fill="#323330"
      />
    </g>
    <defs>
      <clipPath id="clip0_2131_12846">
        <rect width="30" height="30.6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconJavascript;
