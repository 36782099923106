import React from 'react';
import { useDarkMode } from '@leafygreen-ui/leafygreen-provider';

const IconJava = ({ ...styles }) => {
  const { darkMode } = useDarkMode();

  const javaCupFill = darkMode ? 'white' : '#0074BD';
  const javaSteamFill = darkMode ? 'white' : '#EA2D2E';

  return (
    <svg viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
      <path
        d="M10.0921 30.7949C10.0921 30.7949 8.57608 31.6793 11.166 31.9636C14.2929 32.311 15.9037 32.2794 19.3464 31.6161C19.3464 31.6161 20.2623 32.1846 21.5257 32.6901C13.7876 35.9748 4.02791 32.5005 10.0921 30.7949Z"
        fill={javaCupFill}
      />
      <path
        d="M9.14539 26.4678C9.14539 26.4678 7.43985 27.7312 10.0298 27.9838C13.3778 28.3312 15.9992 28.3628 20.579 27.4785C20.579 27.4785 21.2107 28.1101 22.1898 28.4576C12.8724 31.2054 2.44949 28.6787 9.14539 26.4678Z"
        fill={javaCupFill}
      />
      <path
        d="M17.1039 19.1398C18.9989 21.3191 16.5985 23.3089 16.5985 23.3089C16.5985 23.3089 21.431 20.8138 19.2201 17.6869C17.1355 14.7811 15.5563 13.3282 24.1788 8.33789C24.1473 8.33789 10.6607 11.6858 17.1039 19.1398Z"
        fill={javaSteamFill}
      />
      <path
        d="M27.3377 33.9845C27.3377 33.9845 28.4431 34.9004 26.1059 35.6269C21.6525 36.985 7.53417 37.3957 3.61769 35.6901C2.19639 35.0899 4.84949 34.2372 5.67069 34.0477C6.52347 33.8582 7.02884 33.8898 7.02884 33.8898C5.44961 32.7843 -3.10981 36.0691 2.67016 36.9851C18.4308 39.5433 31.4121 35.8164 27.3377 33.9845Z"
        fill={javaCupFill}
      />
      <path
        d="M10.8194 21.983C10.8194 21.983 3.6497 23.6885 8.2926 24.3203C10.2509 24.5729 14.1357 24.5097 17.7995 24.2255C20.7685 23.9728 23.7375 23.4359 23.7375 23.4359C23.7375 23.4359 22.6951 23.8781 21.9372 24.415C14.6411 26.3416 0.585992 25.4257 4.62881 23.4675C8.00839 21.7935 10.8194 21.983 10.8194 21.983Z"
        fill={javaCupFill}
      />
      <path
        d="M23.6745 29.1846C31.0969 25.3313 27.6542 21.6359 25.2538 22.1413C24.6537 22.2676 24.401 22.3623 24.401 22.3623C24.401 22.3623 24.6221 22.0149 25.0327 21.8886C29.7703 20.2146 33.4025 26.7842 23.5166 29.4057C23.5482 29.3741 23.6429 29.2794 23.6745 29.1846Z"
        fill={javaCupFill}
      />
      <path
        d="M19.2206 0.25293C19.2206 0.25293 23.3266 4.35891 15.3357 10.6758C8.92404 15.7293 13.8828 18.6351 15.3357 21.9199C11.6087 18.5403 8.86088 15.5714 10.6928 12.792C13.3775 8.74919 20.863 6.79093 19.2206 0.25293Z"
        fill={javaSteamFill}
      />
      <path
        d="M11.5455 39.6068C18.6521 40.049 29.5803 39.3541 29.833 35.9746C29.833 35.9746 29.3276 37.238 23.9583 38.2487C17.894 39.3858 10.4085 39.2594 5.95508 38.533C5.95508 38.5646 6.87103 39.3225 11.5455 39.6068Z"
        fill={javaCupFill}
      />
    </svg>
  );
};

export default IconJava;
