import React from 'react';

const IconCsharp = ({ ...styles }) => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
    <g clip-path="url(#clip0_1351_59964)">
      <g clip-path="url(#clip1_1351_59964)">
        <path
          d="M25.7709 9.2495C25.7709 8.79827 25.678 8.39408 25.4827 8.04627C25.2874 7.70785 25.0084 7.42586 24.627 7.20025C21.465 5.35781 18.2937 3.51538 15.1317 1.67295C14.2761 1.17474 13.4484 1.19354 12.6021 1.70115C11.3466 2.45316 5.04112 6.10042 3.15321 7.20025C2.3813 7.65142 2 8.34705 2 9.24005C2 12.9531 2 16.6568 2 20.3698C2 20.8117 2.093 21.2064 2.279 21.5449C2.4743 21.8927 2.7626 22.1841 3.15321 22.4191C5.03182 23.5189 11.3373 27.1662 12.6021 27.9182C13.4484 28.4258 14.2761 28.4446 15.1317 27.9463C18.2937 26.1039 21.465 24.2615 24.627 22.4191C25.0177 22.1935 25.306 21.9021 25.5012 21.5449C25.6873 21.2064 25.7803 20.8117 25.7803 20.3698C25.7709 20.3698 25.7709 12.9531 25.7709 9.2495Z"
          fill="#A179DC"
        />
        <path
          d="M13.9227 14.7673L2.27905 21.5449C2.47435 21.8927 2.76265 22.1841 3.15326 22.4191C5.03187 23.5189 11.3373 27.1662 12.6021 27.9182C13.4484 28.4258 14.2762 28.4445 15.1317 27.9463C18.2938 26.1039 21.4651 24.2615 24.6271 22.4191C25.0177 22.1935 25.306 21.9021 25.5013 21.5449L13.9227 14.7673Z"
          fill="#280068"
        />
        <path
          d="M25.7711 9.24949C25.7711 8.79827 25.6781 8.39408 25.4828 8.04626L13.9229 14.7768L25.4921 21.5543C25.6781 21.2159 25.7711 20.8211 25.7711 20.3793C25.7711 20.3699 25.7711 12.9531 25.7711 9.24949Z"
          fill="#390091"
        />
        <path
          d="M20.7771 12.2387V13.5078H22.0325V12.2387H22.6557V13.5078H23.9111V14.1376H22.6557V15.4066H23.9111V16.0364H22.6557V17.3054H22.0325V16.0364H20.7771V17.3054H20.1539V16.0364H18.8984V15.4066H20.1539V14.1376H18.8984V13.5078H20.1539V12.2387H20.7771ZM22.0325 14.1376H20.7771V15.4066H22.0325V14.1376Z"
          fill="white"
        />
        <path
          d="M13.9508 5.87482C17.2151 5.87482 20.0702 7.67022 21.6047 10.3399L21.5861 10.3117L17.7452 12.5489C16.9919 11.2517 15.5969 10.3775 14.0066 10.3587H13.9601C11.5328 10.3587 9.56116 12.3515 9.56116 14.805C9.56116 15.604 9.77503 16.3654 10.1377 17.014C10.9004 18.3488 12.3232 19.2512 13.9601 19.2512C15.6062 19.2512 17.0384 18.3394 17.7917 16.9858L17.7731 17.014L21.614 19.2606C20.0981 21.9021 17.2895 23.6975 14.0531 23.7257H13.9508C10.6679 23.7257 7.81274 21.9209 6.28753 19.2418C5.54353 17.9352 5.11572 16.4124 5.11572 14.7956C5.11572 9.87931 9.06825 5.87482 13.9508 5.87482Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1351_59964">
        <rect width="28" height="28" fill="white" transform="translate(0 0.809265)" />
      </clipPath>
      <clipPath id="clip1_1351_59964">
        <rect width="24" height="27" fill="white" transform="translate(2 1.30966)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCsharp;
